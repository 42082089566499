<template>
  <div class="max-w-7xl mx-auto pb-12 px-4 sm:px-6 lg:px-8">
    <div class="bg-white rounded-lg shadow px-5 py-6 sm:px-6">
      <div class="pb-8 sm:flex sm:items-center sm:justify-between">
        <div>
          <h3 class="text-lg leading-6 font-medium text-gray-900">
            Material de apoio
          </h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">
            Baixe os arquivos e acesse as url úteis para nossos produtos.
          </p>
        </div>
      </div>
      <dd class="mt-3 text-sm text-gray-900">
        <ul class="border border-gray-200 rounded-md divide-y divide-gray-200">
          <li v-for="(item, index) in items" :key="index" class="pl-3 pr-4 py-3 flex items-center justify-between text-sm">
            <div class="w-0 flex-1 flex items-center">
              <svg class="flex-shrink-0 h-5 w-5 text-gray-400" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fill-rule="evenodd" d="M8 4a3 3 0 00-3 3v4a5 5 0 0010 0V7a1 1 0 112 0v4a7 7 0 11-14 0V7a5 5 0 0110 0v4a3 3 0 11-6 0V7a1 1 0 012 0v4a1 1 0 102 0V7a3 3 0 00-3-3z" clip-rule="evenodd" />
              </svg>
              <span class="ml-2 flex-1 w-0 truncate">
                {{ item.title }}
              </span>
            </div>
            <div v-if="item.link" @click="open(item.link)" class="ml-4 flex-shrink-0 font-medium text-primary hover:text-secundary">Ir</div>
            <div v-if="item.path" @click="open(item.path)" class="ml-4 flex-shrink-0 font-medium text-primary hover:text-secundary">Visualizar</div>
          </li>
        </ul>
      </dd>
    </div>
  </div>
</template>

<script>
import { listingsColl } from '@/firebase'

export default {
  data() { return { items: [] } },

  async mounted() {
    if (!this.$store.state.loading) this.$store.commit('setLoading', true)
    await listingsColl.doc('Downloads').get()
    .then((result) => { this.items = result.data().available })
    .catch(() => { this.$toast.error('Falha ao obter o conteúdo, tente novamente') })
    if (this.$store.state.loading) this.$store.commit('setLoading', false)
  },

  methods: {
    open(path) {
      window.open(path, '_blank')
    }
  }
}
</script>